<template>
  <div class="container">
    <!-- <div class="headerTabs">
      <div
        class="headerTabs-item"
        :class="{ 'headerTabs-item-active': tabsAction === index }"
        v-for="(item, index) in tabsList"
        :key="index"
        @click="tablesClick(item, index)"
      >
        {{ item.name }}
      </div>
    </div> -->
    <div class="humanCentent">
      <!-- 统计图 -->
      <div v-show="tabsAction === 0" class="StatisticalDataBox">
        <div class="StatisticalData">
          <div class="StatisticalData_left">
            <div class="echartsItem_title">进场总人数统计</div>
            <div class="echartsItem_condition">
              <div class="titleItem">
                <div class="titleItem_lable">项目筛选：</div>
                <div class="titleItem_input">
                  <el-select
                    v-model="TotalNumberofpeople.orgId"
                    clearable
                    placeholder="请选择"
                    size="mini"
                  >
                    <el-option
                      v-for="item in projectOptions"
                      :key="item.id"
                      :label="item.label"
                      :value="item.id"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="titleItem">
                <div class="titleItem_lable">进场日期：</div>
                <div class="titleItem_picker">
                  <el-date-picker
                    v-model="TotalNumberofpeople.startTime"
                    clearable
                    type="date"
                    placeholder="选择开始日期"
                    size="mini"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
                <div style="margin: 0 10px">~</div>
                <div class="titleItem_picker">
                  <el-date-picker
                    v-model="TotalNumberofpeople.endTime"
                    clearable
                    type="date"
                    placeholder="选择结束日期"
                    size="mini"
                    value-format="yyyy-MM-dd"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="titleItem">
                <el-button
                  type="primary"
                  size="mini"
                  icon="el-icon-search"
                  @click="searchFunc('mobilizationTotalNumber')"
                ></el-button>
                <el-button size="mini" icon="el-icon-refresh-right"></el-button>
              </div>
            </div>
            <div class="echartsItem_tabs">
              <el-tabs v-model="TotalNumberofpeople.activeName">
                <el-tab-pane label="统计图" name="first"></el-tab-pane>
                <el-tab-pane label="统计表" name="second"></el-tab-pane>
              </el-tabs>
            </div>
            <div
              class="echartsItem_text1"
              v-show="TotalNumberofpeople.activeName == 'first'"
            >
              {{ TotalNumberofpeople.startTime }} ~
              {{ TotalNumberofpeople.endTime }} 进场总人数统计
            </div>
            <div
              class="echartsItem_text"
              v-show="TotalNumberofpeople.activeName == 'first'"
            >
              进场人员总人次：{{ TotalNumberofpeople.sum }} 进场人员高峰值：{{
                TotalNumberofpeople.max
              }}
              进场人数平均值：{{ TotalNumberofpeople.avg }}
            </div>
            <div
              id="div_mobilizationTotalNumber"
              v-show="TotalNumberofpeople.activeName == 'first'"
            ></div>
            <div
              class="echartsItem_table"
              v-if="TotalNumberofpeople.activeName == 'second'"
            >
              <el-table
                :data="mobilizationTotalNumberData"
                style="width: 100%"
                border
                height="410"
                :header-cell-style="{ background: '#f5f7fa', color: '#555' }"
              >
                <el-table-column type="index" width="60" label="序号">
                </el-table-column>
                <el-table-column prop="label" label="单位名称" align="center">
                </el-table-column>
                <el-table-column
                  prop="value"
                  label="进场人数"
                  align="center"
                  width="100"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
          <div class="StatisticalData_right">
            <div class="echartsItem_title">工种投入分布</div>
            <div class="echartsItem_condition">
              <div class="titleItem">
                <div class="titleItem_lable">项目筛选：</div>
                <div class="titleItem_input">
                  <el-select
                    v-model="Jobtypes.nameInput"
                    clearable
                    placeholder="请选择"
                    size="mini"
                  >
                    <el-option
                      v-for="item in projectOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    >
                    </el-option>
                  </el-select>
                </div>
              </div>
              <div class="titleItem">
                <div class="titleItem_lable">进场日期：</div>
                <div class="titleItem_picker">
                  <el-date-picker
                    v-model="Jobtypes.startTime"
                    clearable
                    type="date"
                    placeholder="选择开始日期"
                    size="mini"
                  >
                  </el-date-picker>
                </div>
                <div style="margin: 0 10px">~</div>
                <div class="titleItem_picker">
                  <el-date-picker
                    v-model="Jobtypes.endTime"
                    clearable
                    type="date"
                    placeholder="选择结束日期"
                    size="mini"
                  >
                  </el-date-picker>
                </div>
              </div>
              <div class="titleItem">
                <el-button
                  type="primary"
                  size="mini"
                  @click="searchFunc('postInputDistribution')"
                  icon="el-icon-search"
                ></el-button>
                <el-button size="mini" icon="el-icon-refresh-right"></el-button>
              </div>
            </div>
            <div class="echartsItem_tabs">
              <el-tabs v-model="Jobtypes.activeName">
                <el-tab-pane label="统计图" name="first"></el-tab-pane>
                <el-tab-pane label="统计表" name="second"></el-tab-pane>
              </el-tabs>
            </div>
            <div
              class="echartsItem_text1"
              v-show="Jobtypes.activeName == 'first'"
            >
              {{ Jobtypes.startTime }} ~ {{ Jobtypes.endTime }} 进场总人数统计
            </div>
            <div
              class="echartsItem_text"
              v-show="Jobtypes.activeName == 'first'"
            >
              进场人员总人次：{{ Jobtypes.sum }} 进场人员高峰值：{{
                Jobtypes.max
              }}
              进场人数平均值：{{ Jobtypes.avg }}
            </div>
            <div
              id="div_postInputDistribution"
              v-show="Jobtypes.activeName == 'first'"
            ></div>
            <div
              class="echartsItem_table"
              v-if="Jobtypes.activeName == 'second'"
            >
              <el-table
                :data="postInputDistributionData"
                style="width: 100%"
                border
                height="410"
                :header-cell-style="{ background: '#f5f7fa', color: '#555' }"
              >
                <el-table-column type="index" width="60" label="序号">
                </el-table-column>
                <el-table-column prop="label" label="岗位/工种" align="center">
                </el-table-column>
                <el-table-column
                  prop="value"
                  label="进场人数"
                  align="center"
                  width="100"
                >
                </el-table-column>
              </el-table>
            </div>
          </div>
        </div>
        <div class="StatisticalDataBot">
          <div class="echartsItem_title">劳动力曲线</div>
          <div class="echartsItem_condition">
            <div class="titleItem">
              <div class="titleItem_lable">项目筛选：</div>
              <div class="titleItem_input">
                <el-select
                  v-model="laborforce.orgid"
                  clearable
                  placeholder="请选择"
                  @change="searchFunc('LDLQXecharts')"
                >
                  <el-option
                    v-for="item in projectOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="titleItem">
              <div class="titleItem_lable">筛选范围：</div>
              <div class="titleItem_input">
                <el-select
                  v-model="laborforce.Range"
                  clearable
                  placeholder="请选择"
                  @change="changeRange"
                >
                  <el-option
                    v-for="item in laborforceOptions"
                    :key="item.value"
                    :label="item.name"
                    :value="item.value"
                  >
                  </el-option>
                </el-select>
              </div>
            </div>
            <div class="titleItem">
              <div class="titleItem_lable">进场日期：</div>
              <div class="titleItem_picker">
                <el-date-picker
                  v-model="laborforce.startTime"
                  clearable
                  :type="laborforce.datepickerType"
                  placeholder="选择时间"
                  @change="searchFunc('LDLQXecharts')"
                >
                </el-date-picker>
              </div>
            </div>
            <div class="titleItem">
              <el-button
                type="primary"
                size="mini"
                icon="el-icon-search"
                @click="searchFunc('LDLQXecharts')"
              ></el-button>
              <el-button size="mini" icon="el-icon-refresh-right"></el-button>
            </div>
          </div>
          <!-- <div class="echartsItem_tabs">
            <el-tabs v-model="laborforce.activeName">
              <el-tab-pane label="统计图" name="first"></el-tab-pane>
              <el-tab-pane label="统计表" name="second"></el-tab-pane>
            </el-tabs>
          </div> -->
          <div class="echartsItem_text1">
            玉山南方2021年06月 每日进场人数统计
          </div>

          <div id="LDLQXecharts"></div>
        </div>
      </div>
      <!-- 进出记录 -->
      <div
        class="humanCentent-table"
        ref="humanCententTable"
        v-show="tabsAction === 1"
      >
        <div class="humanCentent-table-title">
          <div class="titleItem">
            <div class="titleItem_lable">姓名：</div>
            <div class="titleItem_input">
              <el-input placeholder="请输入内容" v-model="nameInput" clearable>
              </el-input>
            </div>
          </div>
          <div class="titleItem">
            <div class="titleItem_lable">进场日期：</div>
            <div class="titleItem_picker">
              <el-date-picker
                v-model="startTime"
                clearable
                type="date"
                placeholder="选择开始日期"
              >
              </el-date-picker>
            </div>
            <div style="margin: 0 10px">~</div>
            <div class="titleItem_picker">
              <el-date-picker
                v-model="endTime"
                clearable
                type="date"
                placeholder="选择结束日期"
              >
              </el-date-picker>
            </div>
          </div>
          <div class="titleItem">
            <el-button
              type="primary"
              size="mini"
              icon="el-icon-search"
            ></el-button>
            <el-button size="mini" icon="el-icon-refresh-right"></el-button>
          </div>
        </div>
        <div class="humanCententTableCentnet">
          <el-table
            :data="tableData"
            style="width: 100%"
            border
            :height="tableHeight"
            :header-cell-style="{
              background: '#f5f7fa',
              color: '#555',
            }"
          >
            <el-table-column
              prop="date"
              label="日期"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column
              prop="name"
              label="姓名"
              align="center"
              width="180"
            >
            </el-table-column>
            <el-table-column prop="address" label="地址"> </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {
  mobilizationTotalNumber,
  postInputDistribution,
  laborForceCurve,
} from "@/api/humanResourceAnalysis/humanResourceAnalysis";
import { treePro } from "@/api/dbtComon/dbtComon";
import { dateFormat } from "@/util/date";
import { mapGetters } from "vuex";

export default {
  data() {
    return {
      tabsList: [
        {
          name: "统计图",
          value: "统计图",
        },
        {
          name: "进出记录",
          value: "进出记录",
        },
      ],
      laborforceOptions: [
        {
          name: "年",
          value: "年",
        },
        {
          name: "月",
          value: "月",
        },
        {
          name: "日",
          value: "日",
        },
      ],
      tabsAction: 0,
      nameInput: "",
      startTime: "",
      endTime: "",
      tableData: [],
      tableHeight: "33.334vw",
      TotalNumberofpeople: {
        activeName: "first",
        nameInput: "",
        startTime: "",
        endTime: "",
        max: 0,
        avg: 0,
        orgId: undefined,
      },
      Jobtypes: {
        activeName: "first",
        nameInput: "",
        startTime: "",
        endTime: "",
        max: 0,
        avg: 0,
        orgId: undefined,
      },
      laborforce: {
        activeName: "first",
        nameInput: "",
        startTime: "",
        endTime: "",
        Range: "年",
        datepickerType: "year",
        datepickerFormat: "yyyy",
        orgid: "",
      },
      projectOptions: [],
      mobilizationTotalNumberData: [],
      postInputDistributionData: [],
      ldlTableData: [],
    };
  },
  mounted() {
    let that = this;
    let userInfo = that.userInfo;
    treePro({ parentId: userInfo.dept_id }).then((res) => {
      if (res.data.success) {
        that.projectOptions = res.data.data;
        that.initMobilizationTotalNumber();
        that.initPostInputDistribution();
        that.LDLQXecharts();
      }
    });
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  watch: {},
  methods: {
    tablesClick(row, index) {
      this.tabsAction = index;
    },
    initMobilizationTotalNumber() {
      let that = this;
      let TotalNumberofpeople = that.TotalNumberofpeople;
      let { orgId, startTime, endTime } = TotalNumberofpeople;
      if (startTime)
        startTime = dateFormat(new Date(startTime), "yyyy-MM-dd 00:00:00");
      if (endTime)
        endTime = dateFormat(new Date(endTime), "yyyy-MM-dd 23:59:59");

      mobilizationTotalNumber({ orgId, startTime, endTime }).then((res) => {
        var dom = document.getElementById("div_mobilizationTotalNumber");
        this.$echarts.init(dom).clear();
        var myChart = this.$echarts.init(dom, null, {
          renderer: "canvas",
          useDirtyRect: false,
        });

        var data = [];
        if (res.data.success) {
          data = res.data.data;
        }
        var sum = 0;
        var xda = [];
        var yda1 = [];
        var yda2 = [];
        var option = {};
        if (data.length == 0) {
          option = that.getEmptyOption();
        } else {
          data.forEach((item) => {
            xda.push(item.label);
            sum += Number(item.value);
            yda1.push(Number(item.value));
            yda2.push(Number(item.value));
          });

          option = {
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "5%",
              bottom: "1%",
              top: "10%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: xda,
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2d96f1", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#2d96f1", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: yda1,
                name: "进场人次",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
              },
              {
                name: "Number",
                type: "line",
                // stack: 'Total',
                data: yda2,
                itemStyle: {
                  normal: {
                    // barBorderRadius: [5, 5, 0, 0],
                    color: "#3c72ed",
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#3c72ed",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
        }
        that.TotalNumberofpeople.sum = sum;
        var max = Math.max(...yda1) == "-Infinity" ? 0 : Math.max(...yda1);
        that.TotalNumberofpeople.max = max;
        var sum = yda1.reduce((a, b) => a + b, 0);
        that.TotalNumberofpeople.avg = Math.floor(sum / yda1.length) || 0;
        that.mobilizationTotalNumberData = data;
        console.log(option);
        window.addEventListener("resize", myChart.resize());
        option && myChart.setOption(option);
      });
    },
    initPostInputDistribution() {
      let that = this;
      let Jobtypes = that.Jobtypes;
      let { orgId, startTime, endTime } = Jobtypes;
      if (startTime)
        startTime = dateFormat(new Date(startTime), "yyyy-MM-dd 00:00:00");
      if (endTime)
        endTime = dateFormat(new Date(endTime), "yyyy-MM-dd 23:59:59");

      postInputDistribution({ orgId, startTime, endTime }).then((res) => {
        var dom = document.getElementById("div_postInputDistribution");
        this.$echarts.init(dom).clear();
        var myChart = this.$echarts.init(dom, null, {
          renderer: "canvas",
          useDirtyRect: false,
        });
        var data = [];
        if (res.data.success) {
          data = res.data.data;
        }
        var sum = 0;
        var xda = [];
        var yda1 = [];
        var yda2 = [];
        var option = {};
        if (data.length == 0) {
          option = that.getEmptyOption();
        } else {
          data.forEach((item) => {
            xda.push(item.label);
            sum += Number(item.value);
            yda1.push(Number(item.value));
            yda2.push(Number(item.value));
          });

          option = {
            tooltip: {
              trigger: "axis",
            },
            grid: {
              left: "3%",
              right: "5%",
              bottom: "1%",
              top: "10%",
              containLabel: true,
            },
            xAxis: {
              type: "category",
              data: xda,
              boundaryGap: true,
              splitLine: {
                //坐标线
                show: false,
              },
              axisLabel: {
                show: true,
                rotate: -15,
                textStyle: {
                  color: "#FFFFFFs", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
                formatter: function (value) {
                  var res = value;
                  if (res.length > 5) {
                    res = res.substring(0, 4) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                lineStyle: {
                  color: "#4d548a", //更改坐标轴颜色
                },
              },
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#4E548B", //更改刻度尺颜色
                  type: "dashed", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2d96f1", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#2d96f1", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
              },
              axisTick: {
                //show: true,//外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                barGap: "20%" /*这里设置包含关系，只需要这一句话*/,
                barWidth: 15,
                data: yda1,
                name: "进场人次",
                type: "bar",
                smooth: false, //关键点，为true是不支持虚线的，实线就用true
                showSymbol: true,
                symbolSize: 5, //设定实心点的大小
                hoverAnimation: false,
                itemStyle: {
                  normal: {
                    barBorderRadius: [5, 5, 0, 0],
                    color: new this.$echarts.graphic.LinearGradient(
                      0,
                      0,
                      0,
                      1,
                      [
                        {
                          offset: 0,
                          color: "#02efff",
                        },
                        {
                          offset: 1,
                          color: "#1385ff",
                        },
                      ]
                    ),
                  },
                },
                z: 1,
              },
              {
                name: "Number",
                type: "line",
                // stack: 'Total',
                data: yda2,
                itemStyle: {
                  normal: {
                    // barBorderRadius: [5, 5, 0, 0],
                    color: "#3c72ed",
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "#3c72ed",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
        }
        that.TotalNumberofpeople.sum = sum;
        var max = Math.max(...yda1) == "-Infinity" ? 0 : Math.max(...yda1);
        that.TotalNumberofpeople.max = max;
        var sum = yda1.reduce((a, b) => a + b, 0);
        that.TotalNumberofpeople.avg = Math.floor(sum / yda1.length) || 0;
        that.postInputDistributionData = data;
        window.addEventListener("resize", myChart.resize());
        option && myChart.setOption(option);
      });
    },
    // 劳动力曲线
    LDLQXecharts() {
      let that = this;
      var dom = document.getElementById("LDLQXecharts");
      var myChart = this.$echarts.init(dom, null, {
        renderer: "canvas",
        useDirtyRect: false,
      });
      console.log(that.projectOptions);
      if (that.laborforce.orgid == "") {
        that.laborforce.orgid = that.projectOptions[0].id;
      }
      if (that.laborforce.startTime == "") {
        that.laborforce.startTime = new Date().getFullYear() + "";
        console.log(that.laborforce.startTime);
      }
      let dataX = [];
      let data1 = [];
      let option;
      let data = {
        mode: that.laborforce.Range,
        orgId: that.laborforce.orgid,
      };

      if (that.laborforce.Range == "年") {
        data.year = dateFormat(new Date(that.laborforce.startTime), "yyyy");
      }
      if (that.laborforce.Range == "月") {
        data.month = dateFormat(new Date(that.laborforce.startTime), "yyyy-MM");
      }
      if (that.laborforce.Range == "日") {
        data.day = dateFormat(
          new Date(that.laborforce.startTime),
          "yyyy-MM-dd"
        );
      }
      laborForceCurve(data).then((res) => {
        console.log("劳动力曲线", res);
        if (res.data.data.length == 0) {
          option = that.getEmptyOption();
          option && myChart.setOption(option);
        } else {
          res.data.data.forEach((item) => {
            dataX.push(item.label);
            data1.push(item.value);
          });
          option = {
            grid: {
              top: "10%",
              bottom: "5%",
              left: "5%",
              right: "5%",
            },
            xAxis: {
              type: "category",
              boundaryGap: false,
              data: dataX,
              axisTick: {
                show: false, //外刻度
                inside: true, //内刻度
                length: 5,
              },
              axisLabel: {
                textStyle: {
                  color: "#2d96f1", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },

                formatter: function (value) {
                  var res = value;
                  if (res.length > 10) {
                    res = res.substring(0, 9) + "..";
                  }
                  return res;
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#2d96f1", //更改坐标轴颜色
                },
              },
              splitLine: {
                //去除网格线
                show: false,
              },
            },
            yAxis: {
              type: "value",
              splitLine: {
                //坐标线
                show: true,
                lineStyle: {
                  color: "#cfcfcf", //更改刻度尺颜色
                  type: "solid", //'dotted'虚线 'solid'实线
                },
              },
              axisLine: {
                show: true,
                lineStyle: {
                  color: "#46a2f3", //更改坐标轴颜色
                },
              },
              axisLabel: {
                show: true,
                textStyle: {
                  color: "#46a2f3", //更改坐标轴文字颜色
                  fontSize: this.fontSize(0.12), //更改坐标轴文字大小
                },
              },
              axisTick: {
                show: true, //外刻度
                inside: true, //内刻度
                length: 5,
              },
            },

            series: [
              {
                name: "平均人次",
                type: "line",
                // stack: 'Total',
                data: data1,
                symbol: "circle",
                symbolSize: 5, //指示点大小
                smooth: 0.5, //弯曲程度
                itemStyle: {
                  normal: {
                    // barBorderRadius: [5, 5, 0, 0],
                    color: "red",
                  },
                },
                label: {
                  normal: {
                    show: true,
                    position: "top",
                    formatter: "{c}",
                    color: "red",
                    fontSize: 10,
                  },
                },
              },
            ],
          };
          window.addEventListener("resize", myChart.resize);
          option && myChart.setOption(option);
        }
      });
    },
    baseData(option, name, size) {
      var NameData = [];
      var Data_Name = name;
      Data_Name.push(Data_Name[0]);
      Data_Name.splice(0, 1);
      for (var i = 0; i < Data_Name.length; i++) {
        NameData.push(Data_Name[i]);
        if (i + 1 == size) {
          break;
        }
      }
      return NameData;
    },
    // 字体大小
    fontSize(res) {
      let docEl = document.documentElement,
        clientWidth =
          window.innerWidth ||
          document.documentElement.clientWidth ||
          document.body.clientWidth;
      if (!clientWidth) return;
      let fontSize = 100 * (clientWidth / 1920);
      return res * fontSize;
    },
    // 条件查询
    searchFunc(type) {
      let that = this;
      if (type == "mobilizationTotalNumber") {
        that.initMobilizationTotalNumber();
      }
      if (type == "postInputDistribution") {
        that.initPostInputDistribution();
      }
      if (type == "LDLQXecharts") {
        that.LDLQXecharts();
      }
    },
    // 空白option
    getEmptyOption() {
      return {
        title: [
          {
            text: "{A|" + "" + "}",
            left: "center",
            top: "center",
            textStyle: {
              fontSize: "35",
              color: "#FFF",
              lineHieght: 150,
              rich: {
                A: {
                  backgroundColor: {
                    image: require("../../../assets/empty.png"),
                  },
                  height: 300,
                  width: 300,
                },
              },
            },
          },
        ],
      };
    },
    changeRange() {
      if (this.laborforce.Range == "年") {
        this.laborforce.datepickerType = "year";
        this.laborforce.datepickerFormat = "yyyy";
      }
      if (this.laborforce.Range == "月") {
        this.laborforce.datepickerType = "month";
        this.laborforce.datepickerFormat = "MM";
      }
      if (this.laborforce.Range == "日") {
        this.laborforce.datepickerType = "date";
        this.laborforce.datepickerFormat = "yyyy-MM-dd";
      }
      this.laborforce.startTime = "";
    },
  },
};
</script>
<style lang="scss" scoped>
.container {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.headerTabs {
  width: 100%;
  background-color: #fff;
  border-radius: 5px;
  display: flex;
  align-items: center;
  padding: 0 30px;
  box-sizing: border-box;
}

.headerTabs-item {
  padding: 10px 5px;
  margin: 0 20px;
  cursor: pointer;
}

.headerTabs-item-active {
  color: #409eff !important;
  border-bottom: 2px solid #409eff !important;
}

.humanCentent {
  width: 100%;
  // height: calc(100% - 60px);
  height: 100%;
  margin-top: 10px;
}

.humanCentent-table {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  background-color: #fff;
  padding: 20px;
  box-sizing: border-box;
}

.humanCentent-table-title {
  width: 100%;
  display: flex;
  align-items: center;
}

.titleItem {
  display: flex;
  align-items: center;
  margin-right: 20px;
}

.titleItem_lable {
  margin-right: 5px;
}

.titleItem_input {
  width: 150px;
}

.titleItem_picker {
  width: 140px;
}

.humanCententTableCentnet {
  margin-top: 15px;
}

::v-deep .el-table--border {
  border-bottom: 1px solid #ebeef5 !important;
}

.StatisticalDataBox {
  width: 100%;
  // padding-bottom: 10px;
  height: 100%;
  overflow-y: auto;
  overflow-x: hidden;
  box-sizing: border-box;
}

.StatisticalData {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.StatisticalData_left {
  width: calc(50% - 5px);
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  box-sizing: border-box;
}

.StatisticalData_right {
  width: calc(50% - 5px);
  height: 600px;
  background-color: #fff;
  border-radius: 5px;
  padding: 10px 0;
  box-sizing: border-box;
}

.StatisticalDataBot {
  width: 100%;
  height: 600px;
  margin: 10px 0;
  background-color: #fff;
  border-radius: 5px;
}

.echartsItem_title {
  width: 100%;
  padding: 10px 20px;
  box-sizing: border-box;
}

.echartsItem_condition {
  width: 100%;
  display: flex;
  align-items: center;
  padding: 10px 20px;
  box-sizing: border-box;
}

.echartsItem_condition div {
  color: #555 !important;
  font-size: 12px !important;
}

::v-deep .el-tabs__nav-scroll {
  padding: 0 10px !important;
}

::v-deep .el-tabs__item.is-active {
  color: #303133 !important;
}

.echartsItem_text1 {
  width: 100%;
  padding: 3px 0;
  text-align: center;
  color: #409eff;
}

.echartsItem_text {
  width: 100%;
  padding: 3px 0;
  text-align: center;
  color: #555;
  font-size: 12px;
}

#div_mobilizationTotalNumber {
  width: 42.969vw;
  // width: 100%;
  height: 380px;
}

#div_mobilizationTotalNumber div {
  width: 100% !important;
}

#div_postInputDistribution {
  width: 42.969vw;
  // width: 100%;
  height: 380px;
}

#div_postInputDistribution div {
  width: 100% !important;
}

#LDLQXecharts {
  width: 86.51vw;
  height: 450px;
}

#LDLQXecharts div {
  width: 100% !important;
}

.echartsItem_table {
  width: 42.969vw;
  padding: 10px;
  height: 420px;
  box-sizing: border-box;
}

.LDLQXecharts_table {
  width: 86.51vw;
  padding: 10px;
  height: 420px;
  box-sizing: border-box;
}
</style>
